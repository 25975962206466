@media (max-width: 600px) {
      .white-text {
        text-shadow: 0 0 15px #e60000;
      }

      /* لون النص الأزرق مع خلفية بيضاء */
      .blue-text {
        text-shadow: 0 0 15px #fff;
      }

      /* لون النص الأبيض مع خلفية سوداء */
      .white-text-black-bg {
        text-shadow: 0 0 13px #e60000;
      }

      /* لون النص الأزرق مع خلفية سوداء */
      .blue-text-black-bg {
        text-shadow: 0 0 13px #fff;
      }
}