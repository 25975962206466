li{
    margin: 1px 2px;
    font-size: 14px;
    padding: 5px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}
.previous,.next{
    background:  linear-gradient(80deg, rgb(0, 0, 0) 0%, #e60000  100%);
    padding: 5px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    cursor: pointer;
}
.previous{
    margin-left: 4px;
}
.next{
    margin-right: 4px;
}

.selected{
      background:  linear-gradient(80deg, rgb(0, 0, 0) 0%, #e60000 100%);;
}

li{
    color: white;
}

.image::after{
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: black;
    opacity: 40%;
    cursor: pointer;
    transition: all 0.2s;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -ms-transition: all 0.2s;
    -o-transition: all 0.2s;
}

.cart:hover .image::after{
    width: 0%;
}