.a.active{
    animation-name: a;
    animation-duration: 18s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

@keyframes a {
    0%{
        transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
}100%{
          transform: rotate(0deg);
          -webkit-transform: rotate(0deg);
          -moz-transform: rotate(0deg);
          -ms-transform: rotate(0deg);
          -o-transform: rotate(0deg);
}
}