@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* 
.bg-gradient{
  background: linear-gradient(-45deg,#E3BFC3 0%,#DD7A83 30%, #BF9DCD 65%, #E1B79F 100%);
}

.text-gradient {
  background: radial-gradient(
    64.18% 64.18% at 71.16% 35.69%,
    #A44D01 0.89%,
    #E99331 17.23%,
    #be7d02 42.04%,
    #A44D01 55.12%,
    #ad722e 71.54%,
    #643601 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.navbar {
    height: 60;
    background-color: 'rgba(255, 255, 255, 0.3)';
    backdrop-filter: 'blur(5px)';
    opacity: 0.8;
  } */


.bg-gradient{
  /* background: linear-gradient(80deg,#000000 0%, red 100%); */
background: #870000;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #190A05, #870000);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #190A05, #870000); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}